import React from 'react'
import { Link } from 'react-router-dom'



const Loginbtn = () => {
    return (
        <div className="navbar-item">
            <Link to="/login" className="is-size-5 button border-less is-primary" >Login</Link>
        </div>
    )
}


export default Loginbtn