//importing modules 
import React, { Component } from 'react'
import Homepage from './Pages/Homepage/Homepage'
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom"
import LoginPage from './Pages/Auth/LoginPage'
import SignupPage from './Pages/Auth/SignupPage'
import Appbar from './components/Appbar/Appbar'
//=======================================================


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: false
        };
    }

    componentDidMount() {
        this.setState({
            isLogin: false
        })
    }

    componentWillUnmount() {
    }

    logout() {
        this.setState({
            isLogin: false
        })
        console.log(this.state.isLogin)
    }

    login() {
        this.setState({
            isLogin: true,
        })
    }


    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        <Route exact path="/">
                            <Appbar />
                            <Homepage isLogin={this.state.isLogin} logout={this.logout} />
                        </Route>
                        <Route path="/login">
                        <Appbar page="login" />
                            {((this.state.isLogin) ? console.log() : <LoginPage isLogin={this.state.isLogin} login={this.login} />)}

                        </Route>
                        <Route path="/signup">
                        <Appbar page="signup" />
                            {((this.state.isLogin) ? console.log() : <SignupPage isLogin={this.state.isLogin} login={this.login} />)}

                        </Route>
                    </Switch>
                </div>
            </Router>
        )
    }
}

export default App