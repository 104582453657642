//importing routes 
import React from 'react'
import { Link } from 'react-router-dom'
import './appbar.css'
import Loginbtn from './Loginbtn'

//==============================================



function Appbar(props) {
    return (
        <div className="columns">
            <div className="column">
            <nav className="navbar is-primary">
                <div className="navbar-brand">
                    <div className="navbar-item">
                        <span className="icon is-large">
                            <i className="fas fa-terminal is-size-3 "></i>
                        </span>
                        <Link to="/" className="button border-less is-primary" ><h1 className=" is-size-4 "><strong>ColabCode</strong></h1></Link>
                    </div>
                        <div className="navbar-item is-hidden-desktop is-hidden-tablet">
                            {((props.page === "login" || props.page === "signup") ? console.log() : <Loginbtn />)}
                        </div>
                </div>
                <div className="navbar-end is-hidden-mobile">
                    {((props.page === "login" || props.page === "signup") ? console.log() : <Loginbtn />)}

                </div>
            </nav>
            </div>
        </div>
    )
}

export default Appbar