import React from 'react'
import Appbar from '../../components/Appbar/Appbar'
import './homepage.css'
import { Link } from 'react-router-dom'
import coding from '../../images/coding.svg'


class Homepage extends React.Component {
    render() {
        return (
            <div className="has-navbar-fixed-top">
                <div className="hero"></div>

                <div className="main-contain" id="main">
                    <div className="columns">
                        <div className="column">
                            <div className="section">
                                <div className="hero">
                                    <h1 className="title is-size-1 is-size-2-tablet is-size-3-mobile is-unselectable is-color1">
                                        Find Someone <br /> to colaborate <br /> with!
                                </h1>
                                    <div className="hero"></div>
                                    <p className="subtitle is-size-5-tabelt is-size-6-mobile is-unselectable">
                                        ColabCode helps you to get connected with developers <br />
                                        arround the world, and colaborate on open source <br />
                                        projects and build awesome software!! <i className="fa fa-terminal"></i><br />
                                        
                                        <button className="button is-primary get-started-btn">Get Started</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section"></div>
                    <div className="section"></div>
                    <div className="columns">
                        <div className="column">
                            <h1 className="is-size-3 is-size-4-tablet is-size-5-mobile has-text-centered">Want to work on newest technolgy stack?</h1>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column is-3">
                            <p className="has-text-centered">
                                Mobile
                            </p>
                            <p className="has-text-centered is-size-1"><i className="fab fa-android"></i></p>
                            <p className="has-text-centered space-me"><img src="https://img.icons8.com/color/50/000000/flutter.png"></img></p>
                        </div>
                        <div className="column is-3">
                            <p className="has-text-centered">
                                web
                            </p>
                            <p className="has-text-centered is-size-1"><i className="fab fa-react"></i></p>
                            <p className="has-text-centered is-size-1"><i className="fab fa-html5"></i></p>
                        </div>
                        <div className="column is-3">
                            <p className="has-text-centered">
                                Machine learning
                            </p>
                            <p className="has-text-centered"><img src="https://img.icons8.com/dusk/64/000000/python.png"/></p>
                            <p className="has-text-centered"><img src="https://img.icons8.com/dusk/64/000000/artificial-intelligence.png"/></p>
                        </div>
                        <div className="column is-3">
                            <p className="has-text-centered">
                                Others 
                                <p className="has-text-centered is-size-1"><i class="fab fa-docker"></i></p>
                                <p className="has-text-centered is-size-1"><i class="fab fa-bitcoin"></i></p>
                            </p>
                        </div>
                    </div>

                    

                </div>

            </div>
        )
    }
}


export default Homepage