import React from 'react'
import { Link } from 'react-router-dom'

class LoginPage extends React.Component {
    constructor(){
        super()
        this.state={
            email: "",
            password: ""
        }
        this.emailChanger = this.emailChanger.bind(this)
        this.passwordChanger = this.passwordChanger.bind(this)
    }

    emailChanger(event){
        this.setState({
            email: event.target.value
        })
    }

    passwordChanger(event){
        this.setState({
            password: event.target.value
        })
    }

    login(){
        alert(`${this.state.email} ${this.state.password}`)
    }


    render() {
        return (
            <div className="columns">
                <div className="column is-half is-offset-one-quarter">
                    <div className="section">
                        <div className="field">
                            <div className="control has-icons-left">
                                <input type="email" className="input" placeholder="Email" value={this.state.email} onChange={this.emailChanger} />
                                <span className="icon is-left"><i class="fas fa-envelope"></i></span>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control has-icons-left">
                                <input type="password" className="input" placeholder="Password" value={this.state.password} onChange={this.passwordChanger} />
                                <span class="icon is-left">
                                    <i class="fas fa-lock"></i>
                                </span>
                            </div>

                        </div>
                        <div className="container ">
                            <p className="is-color1">Don't have a account? <Link to="/signup" className="is-size-5" >Signup</Link> </p>
                            <button className="button is-primary px-4 is-pulled-right" onClick={()=>{this.login()}}>Login</button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


export default LoginPage